import Constants from "./constants";
import RestApiEndpointMap from "./apiEndpointMap";


export default {
    name: 'Configs',

    //applicationMode: Constants.application.MODE_TESTING_HOME,
    //applicationMode: Constants.application.MODE_DEV,
    applicationMode: Constants.application.MODE_PRODUCTION,

    applicationUrl: {
        [Constants.application.MODE_TESTING_HOME]: 'http://192.168.0.25:8080/',
        [Constants.application.MODE_DEV]: 'https://mergeplateapp.apptec.at/',
        [Constants.application.MODE_PRODUCTION]: 'https://mergeplate.com/',
    },

    documentsUrl: {
        [Constants.application.MODE_TESTING_HOME]: 'http://192.168.0.25:8000/',
        [Constants.application.MODE_DEV]: 'https://mergeplateapi.apptec.at/',
        [Constants.application.MODE_PRODUCTION]: 'https://mergeplate.com/docs',
    },

    restApi: {
        baseURL: {
            [Constants.application.MODE_PRODUCTION]: 'https://api.mergeplate.com/',
            [Constants.application.MODE_DEV]: 'https://mergeplateapi.apptec.at/',
            [Constants.application.MODE_TESTING_HOME]: 'http://192.168.0.25:8000/',
        },
        appSecret: {
            [Constants.application.MODE_PRODUCTION]: 'apptecSecretAHHhhhH?!?!',
            [Constants.application.MODE_DEV]: 'apptecSecretAHHhhhH?!?!',
            [Constants.application.MODE_TESTING_HOME]: 'apptecSecretAHHhhhH?!?!',
        },
        endpoints: RestApiEndpointMap,
    },

    oauth: {
        grant_type: 'password',
        client_id: 2,
        client_secret: {
            [Constants.application.MODE_PRODUCTION]: 'qBowmHoF33l3HJ6V3OHYfadiAny7JB1gV9Uw7nCg',
            [Constants.application.MODE_DEV]: 'qBowmHoF33l3HJ6V3OHYfadiAny7JB1gV9Uw7nCg',
            [Constants.application.MODE_TESTING_HOME]: 'qBowmHoF33l3HJ6V3OHYfadiAny7JB1gV9Uw7nCg',
        }
    },

    stripe: {
        [Constants.application.MODE_DEV]: {
            sdk_url: 'https://js.stripe.com',
            apiVersion: '2020-08-27',
            locale: 'de',
            secret_key: 'sk_test_51KtCyKGvtsFoTQcJpagT5y9TADpLPGhQwopOrImVXmMxjh0utEgEOJvKZDKFhxgJDZFyuA3ZzaINOrl6y1t7fcA700x6rfC6tT',
            publishable_key: 'pk_test_51KtCyKGvtsFoTQcJaSiuspqonnGT4ThsnWRdCtk7WwpKibFZ3rRNIrMnnLyDVfTpLridtsF1X4urm9YFW6zer1tR00jpD0O6jM',
        },
        [Constants.application.MODE_PRODUCTION]: {
            sdk_url: 'https://js.stripe.com',
            apiVersion: '2020-08-27',
            locale: 'de',
            secret_key: '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',
            publishable_key: 'pk_live_51KtCyKGvtsFoTQcJ9uL3Hz1tjWDQ6AyjRn5WfctVEt5SbV3FEoI6OUstJqs2Xj4gj2WhGujIGdXyMexOsJA0Wvax00Toz81Zg0',
        },
    },

    paypal: {
        [Constants.application.MODE_DEV]: {
            return_url: 'https://mergeplateapp.apptec.at/expert/account'
        },
        [Constants.application.MODE_PRODUCTION]: {
            return_url: 'https://mergeplate.com/expert/account'
        },
    },

    googleMaps: {
        //apiKey: 'AIzaSyBG8HcDOuDppdY1ndqGH3ciVIh29IfMK94'
        apiKey: 'AIzaSyCeL4l6hMfi6kILZqWIuIeCBppJ5nXjSGo'
    },

    googleAuthOptions: {
        client_id: '758529367051-bk5gfvoh7a2tmrvclmf744lqrof7duh1.apps.googleusercontent.com',
        scope: 'profile email',
        prompt: 'select_account'
    },

    fbAuthOtions: {
        appId: '5024014874372517',
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v2.8' // use graph api version 2.8
    },

    image: {
        maxFileSizeInKb: 1024*10000      // 10 MB
    },

    openGraph: {
        urlDefaultLogo: '/img/mergeplate_og_logo.jpg',
    }

}
