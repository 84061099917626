//import Const from '../constants';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import moment from "moment";
import Configs from "../configs";


export const mixinBasics = {

    computed: {
        ...mapGetters('main', {
            _profile: 'getUserProfile',
            _categories: 'getCategories',
            _areas: 'getAreas',
            _courseTypes: 'getCourseTypes',
            _courses: 'getCourses',
            _products: 'getProducts',
            __experts: 'getExperts',
            _tags: 'getTags',
            _insurances: 'getInsurances',
            _tagsInUse: 'getTagsInUse',
            _stripe: 'getStripe'
        }),

        ...mapState('main', {
            isAuth: 'stateAuthenticated',
            isStoreInitialized: 'storeInitialized',
            filter: 'filter',
        }),

        _experts() {
            return this.__experts.filter(e => e.subscription_level > 0 || e.user_id === this.profile.id);
        },

        profile() {
            return this._profile.profile;
        },

        favoriteExperts() {
            return this.profile.favoriteExperts.filter(fe => this._experts.find(e => e.user_id === fe.user_id))
                .map(fe => this._experts.find(e => e.user_id === fe.user_id));
        },

        favoriteCourses() {
            return this.profile.favoriteCourses.map(fc => this._courses.find(e => e.id === fc.id));
        },

        keywords() {
            let kw = [];
            this._categories.forEach(c => {
                kw.push(c.title);
                c.tags.forEach(t => {
                    kw.push(t.title)
                });
                c.areas.forEach(a => {
                    kw.push(a.title);
                    a.tags.forEach(t => {
                        kw.push(t.title)
                    });
                });
            });
            return [...new Set(kw)];    // unique keywords
        },

        documentsUrl() {
            return `${Configs.restApi.baseURL[Configs.applicationMode]}storage/docs`;
        }
    },

    methods: {
        ...mapActions('main', {
            _setAuth: 'setAuth',
            _reloadExperts: 'loadExperts'
        }),

        ...mapMutations('main', {}),

        hasRole(user, requiredRoleSlug) {
            if (!user.roles) {
                return false;
            }
            return user.roles.filter(e => e.slug === requiredRoleSlug).length > 0;
        },

        // ------------------------------------------------- layout tweaks
        currentDate() {
            return moment().format('YYYY-MM-DD');
        },

        currentTime() {
            return moment().format('HH:mm');
        },

        currentDateTime() {
            return moment().format('YYYY-MM-DD HH:mm:ss');
        },

        dateFormat(date, format) {
            return moment(date).format(format);
        },

        formatDate(date, withTime = false) {
            return moment(date).format(withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')
        },

        getRandomInt(minimum, maximum) {
            const min = Math.ceil(minimum);
            const max = Math.floor(maximum);
            return Math.floor(Math.random() * (max - min) + min);
        },

        getRandomIntArray(min, max, count) {
            let i = min;
            let ary = Array.from({length: max}, () => i++);
            let rndAry = this.shuffle(ary);
            return rndAry.slice(0, count);
        },

        shuffle(ary) {
            let currIdx = ary.length, rndIdx;
            while (currIdx !== 0) {
                rndIdx = Math.floor(Math.random() * currIdx);
                currIdx--;
                [ary[currIdx], ary[rndIdx]] = [ary[rndIdx], ary[currIdx]];
            }
            return ary;
        },

        /*
        showNotification(msg, msgType, timeoutInMilliSecs = 5000) {
            this.$toast.open({
                progress: true,
                // success, info (default), warning, error, default
                type: msgType ? msgType : 'info',
                multiLine: false,
                message: msg,
                position: 'top',
                duration: timeoutInMilliSecs,
                pauseOnHover: true
            });
        }
        */

        isFavoriteExpert(expertUserId) {
            return this.isAuth ? this.favoriteExperts.find(e => e.user_id === expertUserId) : false;
        },

        isFavoriteCourse(courseId) {
            return this.isAuth ? this.favoriteCourses.find(e => e.id === courseId) : false;
        },

        getExpertByUserId(userId) {
            return this._experts.find(e => e.user_id === userId);
        },

        buildOpenGraphMetaTags(title, desc, url, urlImg = '') {
            let appUrl = Configs.applicationUrl[Configs.applicationMode].slice(0, Configs.applicationUrl[Configs.applicationMode].length - 1);
            let apiUrl = Configs.restApi.baseURL[Configs.applicationMode].slice(0, Configs.restApi.baseURL[Configs.applicationMode].length - 1);
            return [
                {property: 'og:type', content: 'Website', vmid: 'og:type'},
                {property: 'og:title', content: title, vmid: 'og:title'},
                {property: 'og:description', content: desc, vmid: 'og:description'},
                {property: 'og:url', content: `${appUrl}${url}`, vmid: 'og:url'},
                {property: 'og:image', content: urlImg ? urlImg : `${apiUrl}${Configs.openGraph.urlDefaultLogo}`, vmid: 'og:image'}
            ];
        },

        preloadImages(imgUrls) {
            function loadImage(src) {
                return new Promise(function (resolve, reject) {
                    let img = new Image();
                    img.onload = function () {
                        resolve(img);
                    };
                    img.onerror = img.onabort = function () {
                        reject(src);
                    };
                    img.src = src;
                });
            }

            let promises = [];
            for (let i = 0; i < imgUrls.length; i++) {
                promises.push(loadImage(imgUrls[i]));
            }
            return Promise.all(promises);
        },

        /**
         * This function is same as PHP's nl2br() with default parameters.
         *
         * @param {string} str Input text
         * @param {boolean} replaceMode Use replace instead of insert
         * @param {boolean} isXhtml Use XHTML
         * @return {string} Filtered text
         */
        nl2br(str, replaceMode, isXhtml) {

            let breakTag = (isXhtml) ? '<br />' : '<br>';
            let replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },

    }
};
