import Constants from '../../constants';
import {apiCall, apiCallOauth} from '../../helpers';
import Configs from '../../configs';
import {loadStripe} from "@stripe/stripe-js/pure";

const api = Configs.restApi.endpoints.map;



export default {
    namespaced: true,

    state: {
        _storePrefix: 'mp',
        appVersion: '0.1',
        storeInitialized: false,
        stateAuthenticated: false,       // TODO: default = false

        email: undefined,

        user: {
            token: undefined,
            profile: {},
            location: undefined
        },

        roles: undefined,
        categories: undefined,
        areas: undefined,
        courses: undefined,
        courseTypes: undefined,
        experts: undefined,
        tags: undefined,
        tagsInUse: undefined,
        insurances: undefined,
        products: undefined,
        filter: {
            categoryId: undefined,
            areaId: undefined,
            courseTypeId: undefined,
            searchTerm: undefined,
            sortBy: Constants.filterSortBy.LEVEL,
            listStyleTiles: true,
            listContent: undefined,
            resultLimit: undefined
        },

        // table vars.
        savedPaginationState: undefined,
        savedFilters: undefined,
        stripe: undefined
    },

    getters: {
        appVersion: (state) => {
            return state.appVersion;
        },

        getUserAuthToken: (state) => {
            return state.user.token;
        },

        getUserLocation: (state) => {
            return state.user.location;
        },

        getUserProfile: (state) => {
            return state.user;
        },

        isUserAuthenticated: (state) => {
            return state.stateAuthenticated;
        },

        isStoreInitialized: (state) => {
            return state.storeInitialized;
        },

        getRoles: (state) => {
            return state.roles;
        },

        getCategories: (state) => {
            return state.categories;
        },

        getAreas: (state) => {
            return state.areas;
        },

        getTags: (state) => {
            return state.tags;
        },

        getTagsInUse: (state) => {
            return state.tagsInUse;
        },

        getInsurances: (state) => {
            return state.insurances;
        },

        getCourseTypes: (state) => {
            return state.courseTypes;
        },

        getCourses: (state) => {
            return state.courses;
        },

        getExperts: (state) => {
            return state.experts;
        },

        getProducts: (state) => {
            return state.products;
        },

        getSavedPaginationState: (state) => {
            return state.savedPaginationState;
        },

        getSavedFilters: (state) => {
            return state.savedFilters;
        },

        getStripe: (state) => {
            return state.stripe;
        }
    },

    mutations: {
        setAuth(state, data) {
            state.stateAuthenticated = data;
        },

        storeUserData(state, {profile, rememberLogin = false}) {
            console.log('storeUserData');
            state.user.profile = profile;
            state.stateAuthenticated = true;
            if (rememberLogin) {
                // only store the user's token, IF user has explicitly set the 'stay logged-in' option (@ the login-dlg)
                localStorage.setItem(Constants.auth.AUTH_TOKEN, JSON.stringify(state.user.token));
            }
        },

        setUserLocation(state, location) {
            state.user.location = {gpsLat: location.gpsLat, gpsLong: location.gpsLong};
        },

        setUserAuthToken(state, token) {
            console.log('setUserAuthToken');
            state.user.token = token;
        },

        setStoreInitDone(state) {
            console.log('StoreInitDone.');
            state.storeInitialized = true;
        },

        clearUserData(state) {
            localStorage.removeItem(Constants.auth.AUTH_TOKEN);
            state.user.profile = {};
            state.user.token = '';
            state.stateAuthenticated = false;
        },

        setRoles (state, data) {
            state.roles = data;
        },

        setCategories(state, data) {
            state.categories = data;
        },

        setAreas(state, data) {
            state.areas = data;
        },

        setTags(state, data) {
            state.tags = data;
        },

        setTagsInUse(state, data) {
            state.tagsInUse = data;
        },

        setInsurances(state, data) {
            state.insurances = data;
        },

        setCourseTypes(state, data) {
            state.courseTypes = data;
        },

        setCourses(state, data) {
            state.courses = data;
        },

        setExperts(state, data) {
            state.experts = data;
        },

        setProducts(state, data) {
            state.products = data;
        },

        saveTablePaginationState(state, data) {
            state.savedPaginationState = data;
        },

        saveFilters(state, data) {
            state.savedFilters = data;
        },

        setStateUserLogin(state, data) {
            state.stateLogin = data;
        },

        setStateRegister(state, data) {
            state.stateRegister = data;
        },

        setStateRegisterAppUser(state, data) {
            state.stateRegisterAppUser = data;
        },

        setStateRegisterVerification(state, data) {
            state.stateRegisterVerification = data;
        },

        setStatePasswordReset(state, data) {
            state.statePasswordReset = data;
        },

        setStatePasswordSet(state, data) {
            state.statePasswordSet = data;
        },

        setStatePasswordSetConfirm(state, data) {
            state.statePasswordSetConfirm = data;
        },

        setEMail(state, data) {
            state.email = data;
        },

        setStripe(state, data) {
            state.stripe = data;
        }
    },

    actions: {
        /*
            --------------------------------------------------------------------------------------------------
            Store-init.
            --------------------------------------------------------------------------------------------------
        */
        async initStore(context) {
            console.log('store: initStore ' + context.state._storePrefix);
            // preload the basics
            await Promise.all([
                context.dispatch('loadCategories'),
                context.dispatch('loadAreas'),
                context.dispatch('loadTags'),
                context.dispatch('loadTagsInUse'),
                context.dispatch('loadInsurances'),
                context.dispatch('loadCourseTypes'),
                context.dispatch('loadCourses'),
                context.dispatch('loadProducts'),
                //context.dispatch('loadExperts', context.state.user.location)
                context.dispatch('loadExperts'),
                context.dispatch('loadStripe'),
            ]);
            // check if user is already authenticated (reuse existing authToken (if available))
            await context.dispatch('checkIfUserIsAlreadyAuthenticated');
            // flag store init. is done
            context.commit('setStoreInitDone');
        },

        /*
            --------------------------------------------------------------------------------------------------
            API stuff
            --------------------------------------------------------------------------------------------------
        */

        async loadStripe(context) {
            loadStripe.setLoadParameters({advancedFraudSignals: false});
            let res = await loadStripe(Configs.stripe[Configs.applicationMode].publishable_key);
            context.commit('setStripe', res);
        },

        async loadRoles(context) {
            console.log('loadRoles');
            let req = api.user.roles.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setRoles', res.data);
        },

        async loadCategories(context) {
            console.log('loadCategories');
            let req = api.category.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setCategories', res.data);
        },

        async loadAreas(context) {
            console.log('loadAreas');
            let req = api.area.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setAreas', res.data);
        },

        async loadTags(context) {
            console.log('loadTags');
            let req = api.tag.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setTags', res.data);
        },

        async loadInsurances(context) {
            console.log('loadInsurances');
            let req = api.insurance.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setInsurances', res.data);
        },

        async loadTagsInUse(context) {
            console.log('loadTagsInUse');
            let req = api.tag.indexInUse;
            let res = await apiCall(req.url, req.method);
            context.commit('setTagsInUse', res.data);
        },

        async loadCourseTypes(context) {
            console.log('loadCourseTypes');
            let req = api.courseType.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setCourseTypes', res.data);
        },

        async loadCourses(context) {
            console.log('loadCourses');
            let req = api.course.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setCourses', res.data);
        },

        async loadExperts(context, gps) {
            console.log('loadExperts');
            let req = api.expert.index;
            let res = await apiCall(req.url, req.method, gps ? {gps_lat: gps.gpsLat, gps_long: gps.gpsLong} : {});
            context.commit('setExperts', res.data);
        },

        async loadProducts(context) {
            console.log('loadProducts');
            let req = api.product.index;
            let res = await apiCall(req.url, req.method);
            context.commit('setProducts', res.data);
        },

        /*
            ************************************************************
            user login/logout
            ************************************************************
        */
        async checkIfUserIsAlreadyAuthenticated(context) {
            console.log('checkIfUserIsAlreadyAuthenticated');
            let authToken = JSON.parse(localStorage.getItem(Constants.auth.AUTH_TOKEN));
            if (authToken) {
                // store token
                context.commit('setUserAuthToken', authToken);
                // load user environment
                await context.dispatch('loadUserEnvironment', true);
            } else {
                console.log('store: checkAuth: no userToken found.');
            }
        },

        async userLogin(context, {email, password, rememberLogin = true}) {
            console.log('userLogin');
            // req. token
            let req = api.user.auth.login;
            let res = await apiCallOauth(req.url, req.method, {
                grant_type: Configs.oauth.grant_type,
                client_id: Configs.oauth.client_id,
                client_secret: Configs.oauth.client_secret[Configs.applicationMode],
                username: email,
                password: password
            });
            let token = res.access_token;
            // load user-profile
            if (token) {
                context.commit('setUserAuthToken', token);
                await context.dispatch('loadUserEnvironment', rememberLogin);
                return true;
            }
            return false;
        },

        async registerUserViaEMail(context, {email, nickname, password}) {
            console.log('registerUserViaEMail');
            let req = api.user.register;
            return await apiCall(req.url, req.method, {
                nickname: nickname,
                email: email,
                password: password
            });
        },

        async registerUserViaFb(context, accessToken) {
            console.log('registerUserViaFb');
            let req = api.user.registerViaFb;
            let res = await apiCall(req.url, req.method, {access_token: accessToken});
            if (res.error === 0) {
                let token = res.data.access_token;
                context.commit('setUserAuthToken', token);
                await context.dispatch('loadUserEnvironment', true);
                return true;
            }
            return false;
        },

        async registerUserViaGoogle(context, {googleUid, firstname, lastname, email}) {
            console.log('registerUserViaGoogle');
            let req = api.user.registerViaGoogle;
            let res = await apiCall(req.url, req.method, {
                access_token: googleUid,
                firstname: firstname,
                lastname: lastname,
                email: email
            });
            if (res.error === 0) {
                let token = res.data.access_token;
                context.commit('setUserAuthToken', token);
                await context.dispatch('loadUserEnvironment', true);
                return true;
            }
            return false;
        },

        async registerUserResendVerificationToken(context, email) {
            console.log('registerUserResendVerificationCode');
            let req = api.user.verificationResendToken;
            return await apiCall(req.url, req.method, {email: email});
        },

        async registerUserVerify(context, {email, token}) {
            console.log('registerUserVerify');
            let req = api.user.verification;
            return await apiCall(req.url, req.method, {
                email: email,
                token: token
            });
        },

        async loadUserEnvironment(context, rememberLogin = false) {
            // load profile
            await context.dispatch('loadUserProfile', rememberLogin);

            // reload courses if user = expert
            if (context.state.user.profile.role === 'expert') {
                await context.dispatch('loadCourses');
            }
        },

        async loadUserProfile(context, rememberLogin = false) {
            let req = api.user.show;
            let res = await apiCall(req.url, req.method);
            let data = res.data;

            if (res.error && res.error.status === 401) {
                console.log('store: checkAuth: invalid userToken found.')
                await context.dispatch('userLogout');
                return;
            }

            context.commit('storeUserData', {
                profile: {
                    id: data.id,
                    isLocked: data.is_locked,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    nickname: data.nickname,
                    salutation: data.salutation,
                    has_newsletter: data.has_newsletter,
                    has_email_notification: data.has_email_notification,
                    title: data.title,
                    title_trailing: data.title_trailing,
                    email: data.email,
                    role: data.role,
                    favoriteExperts: data.favorite_experts,
                    favoriteCourses: data.favorite_courses,
                    subscription: data.subscription,
                    verified_at: data.verified_at,
                    documents_verified: data.documents_verified,
                    hasPendingDiscount: data.has_pending_discount,
                    pendingDiscountTitle: data.pending_discount_title
                },
                rememberLogin: rememberLogin
            });
        },

        async getFbProfile(context, accessToken) {
            let req = api.user.fbProfile;
            let res = await apiCall(req.url, req.method, {access_token: accessToken});
            return res.data;
        },

        async userLoginFacebook(context, accessToken) {
            let req = api.user.auth.loginFB;
            let res = await apiCall(req.url, req.method, {access_token: accessToken});

            if (res.error === 0) {
                context.commit('setUserAuthToken', res.data.access_token);
                await context.dispatch('loadUserEnvironment', true);
                return true;
            }
            return false;
        },

        async userLoginGoogle(context, {googleUid, firstname, lastname, email}) {
            let req = api.user.auth.loginGoogle;
            let res = await apiCall(req.url, req.method, {
                access_token: googleUid,
                firstname: firstname,
                lastname: lastname,
                email: email
            });

            if (res.error === 0) {
                context.commit('setUserAuthToken', res.data.access_token);
                await context.dispatch('loadUserEnvironment', true);
                return true;
            }
            return false;
        },

        async userLogout(context) {
            console.log('userLogout');
            let reloadCourses = context.state.user.profile.role === 'expert';
            context.commit('clearUserData');
            if (reloadCourses) {
                await context.dispatch('loadCourses');
            }
        },

        setTableCurrPage(context, pageId) {
            context.commit('setTableCurrPage', pageId);
        },

        setAuth(context, data) {
            context.commit('setAuth', data);
        },

    }
}
